import { Stack, Typography } from "@mui/material";
import React from "react";
import { Position } from "../../../types/positionTypes";

interface PlayerBaseInformationSummaryProps {
    position: Position
    programName: string
}

export function PlayerBaseInformationSummary(props: PlayerBaseInformationSummaryProps) {
    const {position, programName} = props
    return(
        <Stack flexDirection='column' alignItems='center' justifyContent='center'>
            <Typography>{programName}</Typography>
            <Typography>{position.positionShortName}</Typography>
        </Stack>
    )
}