import React from "react"
import { InfiniteData, useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { getPlayerData } from "../services/getPlayerData"
import { PlayerItemHeading } from "../components/features/playerItem/PlayerItemHeading"
import LoadingSpinner from "../components/common/LoadingSpinner"
import PageContentWrapper from "../components/common/PageContentWrapper"
import { PlayerItemBody } from "../components/features/playerItem/PlayerItemBody"
import { Box, Divider } from "@mui/material"
import { useAtomValue, useSetAtom } from "jotai"
import { currentPlayerPageNameAtom, filterAtom } from "../data/atoms"
import { PlayerItem } from "../types/playerTypes"

function PlayerItemPage(){
    const { playerItemId } = useParams();
    const setPlayerName = useSetAtom(currentPlayerPageNameAtom)
    const queryClient = useQueryClient();
    const playerFilters = useAtomValue(filterAtom)
    
    // // Get the cached data if available
    const cachedData = queryClient.getQueryData<InfiniteData<PlayerItem[]>>(['players', playerFilters ])?.pages
    .flat()
    .find((player: PlayerItem) => player.id === playerItemId);

    const { isLoading, isSuccess, error ,data: playerData } = useQuery(
        ['playerItem', playerItemId], 
        () => getPlayerData(playerItemId as string),
        {
            initialData: cachedData,
        })
    if (isLoading) return (<LoadingSpinner/>)
    if (error || playerData === undefined) throw new Error('Player data undefined');
    if(isSuccess) setPlayerName(`${playerData.basePlayer.firstName} ${playerData.basePlayer.lastName}`)

    return(
        <PageContentWrapper pageTitle={`CFUTDB | ${playerData.basePlayer.firstName} ${playerData.basePlayer.lastName}`}>
            <Box
                sx={{
                    width: {
                        xs: '95%',
                        sm: '75%',
                    },
                }}
            >
                <PlayerItemHeading 
                    firstName={playerData.basePlayer.firstName} 
                    lastName={playerData.basePlayer.lastName} 
                    jerseyNumber={playerData.basePlayer.jerseyNumber} 
                    overallRating={playerData.ratings.overallRating} 
                    positionShortName={playerData.position.positionShortName} 
                    programName={playerData.program} 
                    logo={playerData.fullImage} 
                    teamName={playerData.basePlayer.currentTeam.name}
                />
                <PlayerItemBody ratings={playerData.ratings} positionShortName={playerData.position.positionShortName}/>
            </Box>
        </PageContentWrapper>
    )
}

export default PlayerItemPage