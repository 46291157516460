import { Box, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { PlayerRatings } from "../../../types/ratingTypes";
import { Position } from "../../../types/positionTypes";
import { getPlayerOverviewRatings } from "../../../utils/getPlayerRatings";

interface PlayerListRatingsProps {
    playerRatings: PlayerRatings
    position: Position
}

export function PlayerListRatings(props: PlayerListRatingsProps){
    const {playerRatings, position} = props
    const {ratingHeaders, ratingValues} = getPlayerOverviewRatings(playerRatings, position)
    return(
        <Grid container 
            sx={{
                spacing: 3.5,
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: 1
            }}
            xs={12}
        >
            {ratingHeaders.map((rating, index) => (
                <Grid item key={index} xs={1}>
                    <Box display='flex' flexDirection='column' alignItems='center'>
                        <Typography 
                            variant='caption' 
                            align="center" 
                            style={{ textAlign: 'center' }}
                        >{rating}</Typography>
                        <Typography variant='body1'>{ratingValues[index]}</Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    )
}