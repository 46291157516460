import axios, { AxiosResponse } from "axios";
import { Team } from "../types/playerTypes";
import { backendApi } from "../constants/apiConstants";

export const getTeams = async (): Promise<Team[]> => {
    try {
      const response: AxiosResponse<Team[]> = await axios.get(backendApi+'/getTeams');
      console.log('reponse', response)
      return response.data;
    } catch (error) {
      console.error('Error fetching player data', error)
      throw error
    }
  }