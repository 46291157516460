import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/material';
import { ZIndexValue } from '../../constants/uiViewConstants';


const LoadingSpinner: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
      }}
    >
      <CircularProgress color='info'/>
    </Box>
  );
};

export default LoadingSpinner;
