import React from 'react';
import { Box, styled } from '@mui/material';

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  height: 'auto',
  borderRadius: '15px',
  overflow: 'hidden',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  background: 'linear-gradient(135deg, #f3ec78, #af4261)',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1,
  },
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'relative',
  zIndex: 2,
}));

interface StyledImageDisplayProps{
    src: string 
    alt: string 
}

export function PlayerCardDisplay({src, alt}: StyledImageDisplayProps){
  return (
    <ImageContainer>
      <StyledImage src={src} alt={alt} loading="lazy" />
    </ImageContainer>
  );
};