import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { ImageData } from "../../../types/playerTypes"
import { PositionShortNames } from "../../../types/positionTypes";
import { PlayerCardDisplay } from "../../common/PlayerCardDisplay";

interface PlayerItemHeadingProps {
    firstName: string
    lastName: string
    jerseyNumber: number
    overallRating: number
    positionShortName: PositionShortNames
    programName: string
    logo: ImageData
    teamName: string
}

export function PlayerItemHeading(props: PlayerItemHeadingProps){
    const {
        firstName,
        lastName,
        jerseyNumber,
        overallRating,
        positionShortName,
        programName,
        logo,
        teamName
    } = props

    return(
        <Box p={2} display="flex" alignItems="center" color='common.white'>
            <Box>
                <PlayerCardDisplay 
                    src={logo.base64Image} 
                    alt={'Logo'} 
                />
            </Box>
            <Box ml={2} flexGrow={1}>
                <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h1">{`${firstName} ${lastName}`}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <strong>Overall Rating:</strong> 
                    <Typography variant="h1">{overallRating}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1"><strong>Jersey:</strong> {jerseyNumber}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1"><strong>Position:</strong> {positionShortName}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1"><strong>Program:</strong> {programName}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1"><strong>Team:</strong> {teamName}</Typography>
                </Grid>
                </Grid>
            </Box>
        </Box>
    )
}