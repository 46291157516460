import React from "react"
import { PlayerList } from "../components/features/players/PlayerList"
import PageContentWrapper from "../components/common/PageContentWrapper"
import { FilterPlayersButton } from "../components/features/filterPlayers/FilterPlayersButton"
import { Box } from "@mui/material"

function PlayersPage(){
    return(
        <PageContentWrapper pageTitle='CFUTDB | All Players'>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
            }}
            >
                <FilterPlayersButton />
            </Box>
            <PlayerList />
        </PageContentWrapper>
    )
}

export default PlayersPage