import { ButtonBase } from "@mui/material";
import React from "react";

interface NavigationButtonProps {
  linkTo: string
  children: React.ReactNode
}

function NavigationButton({linkTo, children}: NavigationButtonProps) {  
    return (
      <ButtonBase href={linkTo} style={{ textAlign: 'left' }}>
        {children}
      </ButtonBase>
    );
  };

export default NavigationButton