import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { getPlayersData } from "../../../services/getPlayerData";
import { PlayerListItem } from "./PlayerListItem";
import { useInfiniteQuery } from "react-query";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useAtomValue } from "jotai";
import { filterAtom } from "../../../data/atoms";
import theme from "../../../theme/theme";

export function PlayerList(){
    const filters = useAtomValue(filterAtom);
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    const {
        data,
        error,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
      } = useInfiniteQuery(
        ['players', filters],
        ({ pageParam = null }) => getPlayersData({ pageParam, filters }),
        {
          getNextPageParam: (lastPage, allPages) => {
            return lastPage.length === 10 ? lastPage[lastPage.length - 1].id : null;
          },
        }
      );

    if (isLoading) return (<LoadingSpinner/>)
    if (error || data === undefined) throw new Error('Player data undefined');
    return (
        <Grid 
            container
            sx={{ 
                width: '100%', 
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: {
                    xs: 0,
                    sm: 2,
                },
            }}
            spacing={2}
        >
            {data.pages.map((page) =>
                page.map((player) => (
                    <PlayerListItem 
                        playerItem = {player}
                        width = {isSm ? '60%' : '90%'}     
                    />       
                ))
            )}
            <Box p={2}>
                {isFetchingNextPage ? (
                <Typography variant='body1' color='common.white'>Loading more...</Typography>
                ) : (
                hasNextPage && <Button sx={{bgColor: 'action', color:'common.white'}} onClick={() => fetchNextPage()}>Load More</Button>
                )}
                {isFetching && !isFetchingNextPage ? <Typography variant='body1' color='common.white'>Fetching...</Typography> : null}
            </Box>
        </Grid>
    )
}