import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { getRecentlyCreatedPlayersData } from "../../../services/getPlayerData";
import { PlayerListItem } from "./PlayerListItem";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useQuery } from "react-query";
import theme from "../../../theme/theme";

export function RecentPlayerList(){  
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));  
    const {
        data: players,
        error,
        isLoading,
      } = useQuery(['recentPlayers'], getRecentlyCreatedPlayersData);

    if (isLoading) return (<LoadingSpinner/>)
    if (error || players === undefined) throw new Error('Player data undefined');
    return (
        <Grid 
            container
            sx={{ 
                width: '100%', 
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: {
                    xs: 0,
                    sm: 1,
                },
            }}
            spacing={2}
        >
                {players.map((player) => (
                    <PlayerListItem 
                        playerItem={player} 
                        width = {isSm ? '70%' : '90%'}   
                    />       
                ))}
        </Grid>
    )
}