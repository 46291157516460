import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Grid, IconButton, useMediaQuery } from '@mui/material';
import NavigationButton from '../../common/NavigationButton';
import { ZIndexValue } from '../../../constants/uiViewConstants';
import MenuDrawer, { DrawerNavigationItem } from './MenuDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import SearchBar from '../search/SearchBar';
import theme from '../../../theme/theme';
import './navbar.css'

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    width: '100%', // Full width on extra small screens
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto', // Auto width on small and larger screens
    marginLeft: theme.spacing(1),
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const navItems: DrawerNavigationItem[] = [
  {
    title: 'Players',
    pathTo: '/players'
  }
]

const Navbar = () => {
  const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(false)

  const toggleDrawer = (newOpen: boolean) => () => {
    setMenuDrawerOpen(newOpen);
  }

  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  
  return (
    <>
      <Box sx={{ height:{'sm': 72,'xs': 42} }}>
        <AppBar sx={{ zIndex: ZIndexValue.Navbar, position: 'sticky' }}>
          <Toolbar>
            <Grid container sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <Grid item xs={1}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={toggleDrawer(true)}
                  sx={{ mr: 2}}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs={5} sm={3} padding={1}>
                  <NavigationButton 
                    linkTo='/'
                  >
                    {menuDrawerOpen ? (
                      <img src={`${process.env.PUBLIC_URL}/images/logos/cfutdb-navbar-logo-dark.png`} width={isSm ? 185 : 105} height={isSm ? 70: 40} className="img-disappear"/>
                    ) : (
                      <img src={`${process.env.PUBLIC_URL}/images/logos/cfutdb-navbar-logo-dark.png`} width={isSm ? 185 : 105} height={isSm ? 70: 40} className='img-appear'/>
                    )}
                  </NavigationButton>
              </Grid>
              <Grid item xs={6} sm={8}>
                {/* TODO: commented until searching is enabled */}
                {/* <SearchBar/> */}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      <MenuDrawer
        open={menuDrawerOpen}
        toggleDrawer={toggleDrawer}
        navItems={navItems}
      />
    </>
  );
};

export default Navbar;
