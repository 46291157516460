import React from "react";
import { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoadingSpinner from "./components/common/LoadingSpinner";
import ErrorBoundary from "./components/common/error/ErrorBoundary";
import HomePage from "./pages/HomePage";
import PlayerItemPage from "./pages/PlayerItemPage";
import PlayersPage from "./pages/PlayersPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

export function Router() {  
    const router = createBrowserRouter([
        {
            path: "/", 
            element: (
            <Suspense fallback={<LoadingSpinner/>}>
              <HomePage/>
            </Suspense>
          ),
          errorElement: <ErrorBoundary error={new Error('Failed to load home page')} />,
        },
        {
          path: "/players", 
          element: (
            <Suspense fallback={<LoadingSpinner/>}>
              <PlayersPage/>
            </Suspense>
          ),
          errorElement: <ErrorBoundary error={new Error('Failed to load players page')} />,
        },
        {
          path: "/players/:playerItemId", 
          element: (
            <Suspense fallback={<LoadingSpinner/>}>
              <PlayerItemPage/>
            </Suspense>
          ),
          errorElement: <ErrorBoundary error={new Error('Failed to load player page')} />,
        },
        {
          path: "/privacy-policy", 
          element: (
            <Suspense fallback={<LoadingSpinner/>}>
              <PrivacyPolicyPage/>
            </Suspense>
          ),
          errorElement: <ErrorBoundary error={new Error('Failed to load privacy policy')} />,
        },
      ]);
  
    return <RouterProvider router={router} />;
  }