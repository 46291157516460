import React from 'react';
import PageContentWrapper from '../PageContentWrapper';

interface ErrorBoundaryProps {
  error: Error;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ error }) => {
  return (
    <PageContentWrapper pageTitle='CFUTDB | Error'>
      <div style={{ padding: '1rem', textAlign: 'center' }}>
        <h1>Something went wrong!</h1>
        <p>{error.message}</p>
      </div>
    </PageContentWrapper>
  );
};

export default ErrorBoundary;
