import React from 'react';
import { Box, Typography, Container } from '@mui/material';

export function PrivacyPolicyContent() {
  return (
    <Container>
      <Box color='common.white'>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          Effective Date: 14/07/2024
        </Typography>
        <br></br>

        <Typography variant="h6" gutterBottom>
          Introduction
        </Typography>
        <Typography variant="body1" gutterBottom>
          Welcome to CFUTDB. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website cfutdb.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not accept the privacy agreement.
        </Typography>
        <br></br>

        <Typography variant="h6" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" gutterBottom>
          We collect information about you in a variety of ways. The information we may collect on the Site includes:
        </Typography>
        <br></br>

        <Typography variant="h6" gutterBottom>
          Derivative Data
        </Typography>
        <Typography variant="body1" gutterBottom>
          Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.
        </Typography>
        <br></br>

        <Typography variant="h6" gutterBottom>
          Google Analytics
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use Google Analytics to collect information about your use of the Site. Google Analytics collects information such as how often users visit the Site, what pages they visit, and what other sites they used prior to coming to the Site. We use the information we get from Google Analytics to improve our Site. Google Analytics collects only the IP address assigned to you on the date you visit the Site, rather than your name or other identifying information. We do not combine the information collected through the use of Google Analytics with personally identifiable information. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit the Site, the cookie cannot be used by anyone but Google. Google’s ability to use and share information collected by Google Analytics about your visits to the Site is restricted by the Google Analytics Terms of Use and the Google Privacy Policy.
        </Typography>
        <br></br>

        <Typography variant="h6" gutterBottom>
          Use of Your Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
        </Typography>
        <Typography component="ul" gutterBottom>
          <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
          <li>Increase the efficiency and operation of the Site.</li>
          <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
          <li>Perform other business activities as needed.</li>
          <li>Resolve disputes and troubleshoot problems.</li>
          <li>Respond to product and customer service requests.</li>
        </Typography>
        <br></br>

        <Typography variant="h6" gutterBottom>
          Cookies and Other Tracking Technologies
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize the Site and improve your experience. When you access the Site, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Site.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use the following types of cookies:
        </Typography>
        <Typography component="ul" gutterBottom>
          <li><strong>Essential Cookies:</strong> These cookies are essential for the website to perform its basic functions.</li>
          <li>Cookies to store your consent to the privacy agreement.</li>
          <li><strong>Analytics and Performance Cookies:</strong> These cookies collect information about how users interact with the website, including what pages are visited most, as well as other analytical data. We use these details to improve how our website functions and to understand how users interact with it.</li>
        </Typography>
        <br></br>

        <Typography variant="h6" gutterBottom>
          Your Choices
        </Typography>
        <Typography variant="body1" gutterBottom>
          Opt-Out of Cookie Usage:
        </Typography>
        <Typography variant="body1" gutterBottom>
          You have the right to accept or reject cookies. You can exercise your cookie preferences by clicking on the appropriate opt-out links provided in our cookie consent banner. Please note that if you choose to reject cookies, you may not be able to access certain parts of the Site.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Do Not Track:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Most web browsers and some mobile operating systems include a Do Not Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
        </Typography>
        <br></br>

        <Typography variant="h6" gutterBottom>
          Security of Your Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
        </Typography>
        <br></br>

        <Typography variant="h6" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. If we make material changes to this policy, we will notify you here, by email, or through a notice on our homepage.
        </Typography>
        <br></br>

        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have questions or comments about this Privacy Policy, please contact us at:
        </Typography>
        <Typography variant="body1" gutterBottom>
          cfutdb@gmail.com
        </Typography>
      </Box>
    </Container>
  )
}