import { Grid } from "@mui/material";
import React from "react";
import { CollapsiblePaper } from "../../common/CollapsiblePaper";
import { PlayerRatings } from "../../../types/ratingTypes";
import { PositionShortNames } from "../../../types/positionTypes";
import { RatingChip } from "./RatingChip";

interface PlayerItemBodyProps{
    ratings: PlayerRatings
    positionShortName: PositionShortNames
}

const passingRatingsPositions = [PositionShortNames.QB]
const receivingRatingsPositions = [PositionShortNames.WR, PositionShortNames.RB]
const ballCarrierRatingsPositions = [
    PositionShortNames.RB, 
    PositionShortNames.QB, 
    PositionShortNames.WR,
    PositionShortNames.TE
]
const blockingRatingsPositions = [
    PositionShortNames.TE,
    PositionShortNames.FB,
    PositionShortNames.LT,
    PositionShortNames.LG,
    PositionShortNames.C,
    PositionShortNames.RG,
    PositionShortNames.RT,
]
const coverageRatingsPositions = [
    PositionShortNames.CB,
    PositionShortNames.FS,
    PositionShortNames.SS,
    PositionShortNames.MLB,
    PositionShortNames.LOLB,
    PositionShortNames.ROLB,
]
const defensivePositions = [
    PositionShortNames.CB,
    PositionShortNames.FS,
    PositionShortNames.SS,
    PositionShortNames.MLB,
    PositionShortNames.LOLB,
    PositionShortNames.ROLB,
    PositionShortNames.LE,
    PositionShortNames.DT,
    PositionShortNames.RE,
]
const passRushRatingsPositions = [
    PositionShortNames.LOLB,
    PositionShortNames.ROLB,
    PositionShortNames.LE,
    PositionShortNames.DT,
    PositionShortNames.RE,
    PositionShortNames.SS,
    PositionShortNames.MLB,
]

const kickingRatingsPositions = [
    PositionShortNames.K,
    PositionShortNames.P,
]

const returnerPositions = [
    PositionShortNames.WR,
    PositionShortNames.CB,
    PositionShortNames.RB,
    PositionShortNames.SS,
    PositionShortNames.FS,
]

export function PlayerItemBody(props: PlayerItemBodyProps){
    const {ratings, positionShortName} = props

    return(
        <Grid container spacing={2}>
            {!kickingRatingsPositions.includes(positionShortName) ? (
                <Grid item xs={12} sm={6}>
                    <CollapsiblePaper title="General" isInitiallyOpen={true}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} sm={3}>
                            <RatingChip label={'SPD'} value={ratings.generalRatings.speedRating} />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <RatingChip label={'ACC'} value={ratings.generalRatings.accelerationRating} />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <RatingChip label={'AGI'} value={ratings.generalRatings.agilityRating} />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <RatingChip label={'AWR'} value={ratings.generalRatings.awarenessRating} />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <RatingChip label={'COD'} value={ratings.generalRatings.changeOfDirectionRating} />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <RatingChip label={'STR'} value={ratings.generalRatings.strengthRating} />
                        </Grid>
                        { defensivePositions.includes(positionShortName) ?( 
                            <Grid item xs={4} sm={3}>
                                <RatingChip label={'PRC'} value={ratings.generalRatings.playRecognitionRating} />
                            </Grid>): null
                        }
                        { returnerPositions.includes(positionShortName) ?( 
                            <Grid item xs={4} sm={3}>
                                <RatingChip label={'RET'} value={ratings.generalRatings.returnRating} />
                            </Grid>): null
                        }
                    </Grid>
                    </CollapsiblePaper>
                </Grid>) : null 
            }
            
            { passingRatingsPositions.includes(positionShortName) ? (  
                <Grid item xs={12} sm={6}>
                    <CollapsiblePaper title="Passing" isInitiallyOpen={true}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label={'BSK'} value={ratings.passingRatings.breakSackRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label={'RUN'} value={ratings.passingRatings.throwOnTheRunRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label={'DAC'} value={ratings.passingRatings.deepAccuracyRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label={'SAC'} value={ratings.passingRatings.shortAccuracyRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label={'MAC'} value={ratings.passingRatings.middleAccuracyRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label={'TUP'} value={ratings.passingRatings.throwUnderPressureRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label={'THP'} value={ratings.passingRatings.throwPowerRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label={'PAC'} value={ratings.passingRatings.playActionRating} />
                            </Grid>
                        </Grid>
                    </CollapsiblePaper>
                </Grid>) : null
            }
            {ballCarrierRatingsPositions.includes(positionShortName) && (
                <Grid item xs={12} sm={6}>
                    <CollapsiblePaper title="Ball Carrier" isInitiallyOpen={true}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={3}><RatingChip label={'CAR'} value={ratings.ballCarrierRatings.carryingRating} /></Grid>
                            <Grid item xs={4} sm={3}><RatingChip label={'BCV'} value={ratings.ballCarrierRatings.ballCarrierVisionRating} /></Grid>
                            <Grid item xs={4} sm={3}><RatingChip label={'JUK'} value={ratings.ballCarrierRatings.jukeMoveRating} /></Grid>
                            <Grid item xs={4} sm={3}><RatingChip label={'SPM'} value={ratings.ballCarrierRatings.spinMoveRating} /></Grid>
                            <Grid item xs={4} sm={3}><RatingChip label={'TRK'} value={ratings.ballCarrierRatings.truckRating} /></Grid>
                            <Grid item xs={4} sm={3}><RatingChip label={'SFA'} value={ratings.ballCarrierRatings.stiffArmRating} /></Grid>
                            <Grid item xs={4} sm={3}><RatingChip label={'BTK'} value={ratings.ballCarrierRatings.breakTackleRating} /></Grid>
                        </Grid>
                    </CollapsiblePaper>
                </Grid>
            )}
            {kickingRatingsPositions.includes(positionShortName) && (
                <>
                    <Grid item xs={12} sm={6}>
                        <CollapsiblePaper title="Kicking" isInitiallyOpen={true}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sm={3}>
                                    <RatingChip label="KPW" value={ratings.kickingRatings.kickPowerRating} />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <RatingChip label="KAC" value={ratings.kickingRatings.kickAccuracyRating} />
                                </Grid>
                            </Grid>
                        </CollapsiblePaper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CollapsiblePaper title="Fake" isInitiallyOpen={true}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sm={3}>
                                    <RatingChip label="SPD" value={ratings.fakeRatings.speedRating} />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <RatingChip label="ACC" value={ratings.fakeRatings.agilityRating} />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <RatingChip label="AGI" value={ratings.fakeRatings.accelerationRating} />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <RatingChip label="THP" value={ratings.fakeRatings.throwPowerRating} />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <RatingChip label="RUN" value={ratings.fakeRatings.throwOnTheRunRating} />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <RatingChip label="SAC" value={ratings.fakeRatings.shortAccuracyRating} />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <RatingChip label="MAC" value={ratings.fakeRatings.middleAccuracyRating} />
                                </Grid>
                            </Grid>
                        </CollapsiblePaper>
                    </Grid>
                </>
            )}

            {coverageRatingsPositions.includes(positionShortName) && (
                <Grid item xs={12} sm={6}>
                    <CollapsiblePaper title="Coverage" isInitiallyOpen={true}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="MCV" value={ratings.coverageRatings.manCoverageRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="ZCV" value={ratings.coverageRatings.zoneCoverageRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="PRS" value={ratings.coverageRatings.pressRating} />
                            </Grid>
                        </Grid>
                    </CollapsiblePaper>
                </Grid>
            )}

            {defensivePositions.includes(positionShortName) && (
                <Grid item xs={12} sm={6}>
                    <CollapsiblePaper title="Tackling" isInitiallyOpen={true}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="TCK" value={ratings.tacklingRatings.tackleRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="HPW" value={ratings.tacklingRatings.hitPowerRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="PUR" value={ratings.tacklingRatings.pursuitRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="BSH" value={ratings.tacklingRatings.blockSheddingRating} />
                            </Grid>
                        </Grid>
                    </CollapsiblePaper>
                </Grid>
            )}

            {passRushRatingsPositions.includes(positionShortName) && (
                <Grid item xs={12} sm={6}>
                    <CollapsiblePaper title="Pass Rush" isInitiallyOpen={true}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="FMV" value={ratings.passRushRatings.finesseMoveRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="PMV" value={ratings.passRushRatings.powerMoveRating} />
                            </Grid>
                        </Grid>
                    </CollapsiblePaper>
                </Grid>
            )}

            {blockingRatingsPositions.includes(positionShortName) && (
                <Grid item xs={12} sm={6}>
                    <CollapsiblePaper title="Blocking" isInitiallyOpen={true}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="RBK" value={ratings.blockingRatings.runBlockRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="RBF" value={ratings.blockingRatings.runBlockFinesseRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="RBP" value={ratings.blockingRatings.runBlockPowerRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="PBK" value={ratings.blockingRatings.passBlockRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="PBF" value={ratings.blockingRatings.passBlockFinesseRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="PBP" value={ratings.blockingRatings.passBlockPowerRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="IBK" value={ratings.blockingRatings.impactBlockRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="LBK" value={ratings.blockingRatings.leadBlockRating} />
                            </Grid>
                        </Grid>
                    </CollapsiblePaper>
                </Grid>
            )}

            {receivingRatingsPositions.includes(positionShortName) && (
                <Grid item xs={12} sm={6}>
                    <CollapsiblePaper title="Receiving" isInitiallyOpen={true}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="CTH" value={ratings.receivingRatings.catchRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="CIT" value={ratings.receivingRatings.catchInTrafficRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="SPC" value={ratings.receivingRatings.spectacularCatchRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="SRR" value={ratings.receivingRatings.shortRouteRunningRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="MRR" value={ratings.receivingRatings.middleRouteRunningRating} />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <RatingChip label="DRR" value={ratings.receivingRatings.deepRouteRunningRating} />
                            </Grid>
                        </Grid>
                    </CollapsiblePaper>
                </Grid>
            )}
        </Grid>
    )
}