import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { ZIndexValue } from '../../constants/uiViewConstants';
import NavigationBreadcrumbs from './NavigationBreadcrumbs';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4'

interface CenteredComponentWrapperProps {
    children: React.ReactNode;
    showBreadcrumbs?: boolean
    pageTitle: string
  }

function PageContentWrapper({children, showBreadcrumbs=true, pageTitle}: CenteredComponentWrapperProps) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box>
        {showBreadcrumbs ? <NavigationBreadcrumbs sx={{paddingBottom: 2}} /> : (null)}
        <Box width="100%" height="100%" display="flex" alignItems='center' justifyContent="flex-start" flexDirection="column" zIndex={ZIndexValue.Content}>
          {children}
        </Box>
      </Box>
    </>
  )
}

export default PageContentWrapper
