import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import NavigationButton from '../../common/NavigationButton';
import { Button } from '@mui/material';
import './menuDrawer.css'

export interface DrawerNavigationItem {
    title: string
    pathTo: string
}

interface MenuDrawerProps {
  open: boolean;
  toggleDrawer: (newOpen: boolean) => () => void
  navItems: DrawerNavigationItem[];
}

export default function MenuDrawer(props: MenuDrawerProps) {
  const { open, toggleDrawer, navItems } = props;

  const drawer = (
    <Box onClick={toggleDrawer(false)} sx={{ textAlign: 'center', backgroundColor: 'primary.main'}}>
      <Box padding={1}>
        {open ? (
          <img src={`${process.env.PUBLIC_URL}/images/logos/cfutdb-navbar-logo-dark.png`} width={185} height={70} className="img-appear"/>
        ) : (
          <img src={`${process.env.PUBLIC_URL}/images/logos/cfutdb-navbar-logo-dark.png`} width={185} height={70} className='img-disappear'/>
        )}
      </Box>
      <Divider sx={{bgcolor:'common.white',}} />
      <List>
        {navItems.map((item, index) => (
          <Button key={index}>
            <NavigationButton 
                linkTo={item.pathTo} 
            >
              <Typography variant="h6" color='common.white'>
                {item.title}
              </Typography>
            </NavigationButton>
          </Button>
        ))}
      </List>
    </Box>
  )

  return (
      <Drawer
        variant="temporary"
        open={open}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { 
            width: 250,
            backgroundColor: 'primary.main',
          },
        }}
      >
        {drawer}
      </Drawer>
  );
}
