import axios, { AxiosResponse } from "axios";
import { PlayerItem } from "../types/playerTypes";
import { PlayerFilters } from "../types/filters";
import { backendApi } from "../constants/apiConstants";

interface GetPlayersDataParams {
  pageParam?: string | null;
  filters: PlayerFilters;
}

export const getPlayersData = async ({ pageParam = null, filters }: GetPlayersDataParams): Promise<PlayerItem[]> => {
  const [firstName, ...lastNameParts] = filters.fullName.split(' ');
  const lastName = lastNameParts.join(' ');
  try {
    const response: AxiosResponse<PlayerItem[]> = await axios.get(backendApi+'/getPlayersData', {

      params: {
        limit: 10,
        startAfter: pageParam,
        firstName: firstName,
        lastName: lastName,
        teamName: filters.teamName,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching player data', error)
    throw error
  }
}

export const getRecentlyCreatedPlayersData = async (): Promise<PlayerItem[]> => {

  try {
    const response: AxiosResponse<PlayerItem[]> = await axios.get(backendApi+'/getRecentlyCreatedPlayersData', {
      params: {
        limit: 5,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching player data', error)
    throw error
  }
}
  
export const getPlayerData = async (playerId: string): Promise<PlayerItem> => {
  try {
    const response: AxiosResponse<PlayerItem> = await axios.get(backendApi+'/getIndividualPlayerData', {
      params: { playerId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching player data', error)
    throw error
  }
}
  