// NavigateWrapper.tsx
import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NavigationWrapperProps {
  to: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>
}

//Only use this in components that are inside the react router
const NavigationWrapper: React.FC<NavigationWrapperProps> = ({ to, children, sx }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };

  return (
    <Box onClick={handleClick} sx={{...sx}}>
      {children}
    </Box>
  );
};

export default NavigationWrapper;
