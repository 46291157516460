export interface Position {
    id: string
    positionName: PositionNames
    positionShortName: PositionShortNames
    positionType: PositionTypes
}

export enum PositionTypes {
    Offense = 'Offense',
    Defense = 'Defense',
    SpecialTeams = 'Special Teams'
}

export enum PositionNames {
    Quarterback = 'Quarterback',
    RunningBack = 'Running back',
    WideReceiver = 'Wide Receiver',
    LeftTackle = 'Left Tackle',
    RightTackle = 'Right Tackle',
    RightGuard = 'Right Guard',
    LeftGuard = 'Left Guard',
    Center = 'Center',
    TightEnd = 'Tight End',
    Fullback = 'Fullback',
    DefensiveTackle = 'Defensive Tackle',
    LeftEnd = 'Left End',
    RightEnd = 'Right End',
    MiddleLinebacker = 'Middle Linebacker',
    LeftOutsideLinebacker = 'Left Outside Linebacker',
    RightOutsideLinebacker = 'Right Outside Linebacker',
    Cornerback = 'Cornerback',
    FreeSafety = 'Free Safety',
    StrongSafety = 'Strong Safety',
    Punter = 'Punter',
    Kicker = 'Kicker'
}

export enum PositionShortNames {
    QB='QB',
    RB='HB',
    WR='WR',
    LT='LT',
    RT='RT',
    RG='RG',
    LG='LG',
    C='C',
    TE='TE',
    FB='FB',
    DT='DT',
    LE='LE',
    RE='RE',
    MLB='MLB',
    LOLB='LOLB',
    ROLB='ROLB',
    CB='CB',
    FS='FS',
    SS='SS',
    P='P',
    K='K'
}