import styled from "@emotion/styled";
import { Link as RouterLink } from 'react-router-dom'


export const CustomLink = styled(RouterLink)({
    textDecoration: 'none',
    position: 'relative',
    color: 'inherit', // Use this if you want the link color to be inherited from its parent
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '2px', // Adjust the height of the underline
      bottom: 0,
      left: 0,
      backgroundColor: 'white', // The color of the underline
      visibility: 'visible',
      transform: 'scaleX(1)',
      transition: 'all 0.3s ease-in-out',
    },
    '&:hover::after': {
      transform: 'scaleX(0)', // Animation effect on hover
      visibility: 'hidden',
    },
  });