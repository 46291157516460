import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { getTeams } from "../../../services/getTeamData";
import { useQuery } from "react-query";
import { PlayerFilters } from "../../../types/filters";

interface FilterTeamsProps {
    teamId: string
    setLocalFilters: React.Dispatch<React.SetStateAction<PlayerFilters>>
}

export function FilterTeams(props: FilterTeamsProps) {
    const {teamId, setLocalFilters} = props
    const {data: teams, isLoading, isError} = useQuery(['teams'], getTeams)

    return(
        <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="team-label">Team</InputLabel>
            <Select
                labelId="team-label"
                label="Team"
                value={teamId}
                color='primary'
                onChange={(e) => setLocalFilters((prev) => ({ ...prev, teamName: e.target.value }))}
                fullWidth
                sx={{ mb: 2 }}
                MenuProps={{
                PaperProps: {
                    style: {
                    maxHeight: 200, // Set the max height for the dropdown
                    marginTop: 8,   // Add some margin to ensure the dropdown appears below the input field
                    },
                },
                }}
            >
                {
                isLoading ? (<p>Loading...</p>) :
                isError || !teams ? (<p>No results found.</p>) :
                (
                    teams.map((team)=>(
                    <MenuItem key={team.id} value={team.name}>{team.name}</MenuItem>
                    ))
                ) 
                }
            </Select>
        </FormControl>
    )
}