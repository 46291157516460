import ReactGA from 'react-ga4'

export function initGoogleAnalytics(id: string){
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(id)
    ReactGA.event({
      category: 'User',
      action: 'Consented to cookies',
      label: 'Cookies Consent'
    })
  }
}