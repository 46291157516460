import React, { useState } from 'react';
import { Box, Button, Typography, Divider, IconButton, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { emptyFilterAtomData, filterAtom } from '../../../data/atoms';
import ClearIcon from '@mui/icons-material/Clear';
import { FilterTeams } from './FilterTeams';
import { PlayerFilters } from '../../../types/filters';
import { FilterPlayerName } from './FilterPlayerName';

interface FilterPlayersModalProps {
  onClose: () => void;
}

export function FilterPlayersModal(props: FilterPlayersModalProps) {
  const { onClose } = props;
  const [filters, setFilters] = useAtom(filterAtom);

  // Local state for managing filter inputs
  const [localFilters, setLocalFilters] = useState<PlayerFilters>({
    fullName: filters.fullName,
    teamName: filters.teamName,
  });

  const handleFilter = () => {
    // Update the filter atom with the local filters
    setFilters({
      fullName: localFilters.fullName,
      teamName: localFilters.teamName,
    });
    onClose();
  };

  const onClear = () => {
    setFilters(emptyFilterAtomData)
    setLocalFilters(emptyFilterAtomData)
  }

  return (
    <Box 
      onClick={(event) => event.stopPropagation()}
      sx={{ 
        p: 2, 
        bgcolor: 'background.paper', 
        borderRadius: 2, 
        boxShadow: 24, 
        overflowY: 'auto',
        height: '70vh',
        width: {
          xs: '80vw',
          sm: '40vw'
        }
      }}

    >
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Typography variant="h6" component="h2">
          Filters
        </Typography>
        <IconButton color='primary' onClick={onClose}>
          <ClearIcon/>
        </IconButton>
      </Box>
      <Divider sx={{ my: 2 }} />

      {/* <FilterPlayerName fullName={localFilters.fullName} setLocalFilters={setLocalFilters}/> */}
      <FilterTeams teamId={localFilters.teamName} setLocalFilters={setLocalFilters} />

      <Stack display='flex' justifyContent='space-between' spacing={2}>
        <Button variant="outlined" onClick={onClear}>
          Clear Filters
        </Button>
        <Button variant="contained" color="primary" onClick={handleFilter}>
          Apply Filters
        </Button>
      </Stack>
    </Box>
  );
}
