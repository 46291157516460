import { Avatar, Box, Typography, Badge, Grid  } from "@mui/material"
import React from "react"
import { PlayerItem } from "../../../types/playerTypes"
import { PlayerListRatings } from "./PlayerListRatings"
import NavigationWrapper from "../../common/NavigationWrapper"
import { PlayerBaseInformationSummary } from "./PlayerBaseInformationSummary"

export interface PlayerListItemProps {
    playerItem: PlayerItem
    width: string
}

export function PlayerListItem(props:PlayerListItemProps){
    const { playerItem, width } = props

    return(
        <Grid 
            item
            key={playerItem.id}
           width={width}
        >
            <NavigationWrapper to={`/players/${playerItem.id}`}>
                <Box 
                    sx={{
                        backgroundColor: 'info.main',
                        '&:hover': { 
                            cursor: 'pointer', 
                            backgroundColor: 'info.light'
                        },
                        borderRadius: 2,
                    }}
                >
                    <Badge 
                        badgeContent={`${playerItem.ratings.overallRating}`} 
                        color="primary" 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={{
                            display:'block',
                        }}
                    >
                        <Grid 
                            container 
                            sx={{
                                height: {
                                    xs: 85,
                                    sm: 70
                                },
                                s: {
                                    xs:1,
                                    sm:3,
                                },
                                display: 'flex', 
                                flexDirection: {sm: 'column', md: 'row'}, 
                                justifyContent: 'space-between', 
                                alignItems: 'center',
                                px: 0.5
                            }}
                        >
                                <Grid item xs={1} sm={1}>
                                    {/* Referring to relative static path until BE is implemented */}
                                    <Avatar sx={{width: 50, height: 50}}>
                                        <img src={playerItem.avatarImage.base64Image} loading='lazy'/>
                                    </Avatar>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <Typography variant='h2' paddingLeft={1}>
                                        {`${playerItem.basePlayer.firstName} ${playerItem.basePlayer.lastName}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <PlayerBaseInformationSummary position={playerItem.position} programName={playerItem.program}/>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <PlayerListRatings playerRatings={playerItem.ratings} position={playerItem.position} />
                                </Grid>
                        </Grid>
                    </Badge>
                </Box>
            </NavigationWrapper>
        </Grid>
    )
}