// FilterButton.tsx
import React, { useState } from 'react';
import { Modal, Box, IconButton, Backdrop } from '@mui/material';
import {FilterPlayersModal} from './FilterPlayersModal';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ZIndexValue } from '../../../constants/uiViewConstants';

export function FilterPlayersButton() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <IconButton onClick={handleOpen} size='large'>
        <FilterAltIcon color='action'/>
      </IconButton>
      <Modal 
        open={open} 
        onClose={handleClose} 
        sx={{zIndex: ZIndexValue.Popup}}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh',
          }}
          onClick={()=>handleClose()}
        >
          <FilterPlayersModal onClose={handleClose} />
        </Box>
      </Modal>
    </div>
  );
};