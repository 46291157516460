import { atom } from 'jotai';
import { PlayerFilters } from '../types/filters';

export const currentPlayerPageNameAtom = atom<string>('');

export const emptyFilterAtomData = {
  fullName: '',
  teamName: '',
}

export const filterAtom = atom<PlayerFilters>(emptyFilterAtomData);