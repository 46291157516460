import React from 'react'
import { Chip, Divider, Typography, Box } from '@mui/material'
import * as d3 from 'd3'

interface RatingChipProps {
  label: string
  value: number
}

const rgbToRgba = (rgb: string, alpha: number): string => {
  const rgba = rgb.replace('rgb', 'rgba').replace(')', `, ${alpha})`);
  return rgba;
};

const customInterpolator = (t: number): string => {
  const alpha = 0.75
  if (t <= 0.5) {
    return rgbToRgba('rgb(255, 0, 0)',alpha); // Keep it red for the first half
  } else if (t <= 0.8) {
    return rgbToRgba(d3.interpolateRgb("red", "yellow")((t - 0.5) / 0.3),alpha); // Transition from red to yellow
  } else {
    return rgbToRgba(d3.interpolateRgb("yellow", "green")((t - 0.8) / 0.2),alpha); // Transition from yellow to green
  }
};

export function RatingChip(props: RatingChipProps){
    const {label, value} = props

    const colorScale = d3.scaleSequential(customInterpolator)
    .domain([0, 99])

    return (
        <Chip
        label={
            <Box display="flex" alignItems="center" justifyContent='space-between'>
              <Typography variant="body2">{label}:</Typography>
              <Box
                  sx={{
                    padding: '0 4px',
                    borderRadius: '4px',
                  }}
              >
                <Typography variant="body2">{value}</Typography>
              </Box>
            </Box>
        }
        variant="outlined"
        sx={{
          backgroundColor: colorScale(value),
          width: {
            xs:65,
            sm:70,
          },
        }}
        />
    )
}