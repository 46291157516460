import React from 'react'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import { Breadcrumbs, SxProps, Theme, Typography } from '@mui/material'
import { useAtomValue } from 'jotai'
import { currentPlayerPageNameAtom } from '../../data/atoms'
import { CustomLink } from './CustomLink'

interface NavigationBreadcrumbsProps {
  sx?: SxProps<Theme>
}

function NavigationBreadcrumbs({sx}: NavigationBreadcrumbsProps){
  const location = useLocation()
  const playerName = useAtomValue(currentPlayerPageNameAtom)
  const { playerItemId } = useParams<{ playerItemId: string }>()

  const pathnames = location.pathname.split('/').filter((x) => x)

  const formatPathname = (pathname: string) => {
    return pathname
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" color='common.white' sx={{...sx, color:'common.white', mt:1}}>
      <CustomLink to="/" >
        <Typography>
          Home
        </Typography>
      </CustomLink>
      {pathnames.map((value, index) => {
        const formattedName = formatPathname(value)
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`

        return last ? (
          <Typography key={to}>
            {playerItemId ? playerName : value.charAt(0).toUpperCase() + formattedName.slice(1)}
          </Typography>
        ) : (
          <CustomLink to={to} key={to}>
            <Typography>
              {value.charAt(0).toUpperCase() + formattedName.slice(1)}
            </Typography>
          </CustomLink>
        )
      })}
    </Breadcrumbs>  
  )
}

export default NavigationBreadcrumbs
