import React, { useState } from 'react';
import { Typography, Button, Snackbar, SnackbarContent, Link, Grid } from '@mui/material';

interface ConsentBannerProps{
  handleAcceptCookies: () => void
  handleDeclineCookies: () => void
}

export function ConsentBanner(props: ConsentBannerProps){
  const {handleAcceptCookies, handleDeclineCookies} = props
  const [showBanner, setShowBanner] = useState(true);

  const handleAccept = () => {
    handleAcceptCookies()
    setShowBanner(false)
  }

  const handleDecline = () => {
    handleDeclineCookies()
    setShowBanner(false)
  }

  return (
    <Snackbar open={showBanner}>
      <SnackbarContent
        sx={{height:{xs: 120, sm: 100},}}
        message={
          <Grid 
            container
            display="flex" 
            alignItems="center" 
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={8} sm={9}>
              <Typography variant="body1">
                We use cookies to improve your experience. By using our site, you agree to our use of cookies. Do you accept?
                <Link href="/privacy-policy">
                  <Typography variant="body1" color="secondary" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' }, }}>
                    Read our privacy policy
                  </Typography>
                </Link>
              </Typography>
            </Grid>
            <Grid container 
              xs={4} 
              sm = {3}
              spacing={1}
              display='flex'
              justifyContent='center'
              alignItems='center'
              height='100%'
              sx={{
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}
            >
              <Grid item>
                <Button onClick={handleAccept} color="primary" variant="contained" sx={{size:{xs:'small',sm:'large',}}}>
                  Accept
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleDecline} color="secondary" variant="outlined" sx={{size:{xs:'small',sm:'large',}}}>
                  Decline
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </Snackbar>
  );
};