import React from 'react';
import { Container, Box, Typography, Link } from '@mui/material';

export function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="body2" color="white" align="center">
          {'© '}
          {new Date().getFullYear()}
          {' CFUTDB.'}
          {' | '}
          <Link href="/privacy-policy" color="inherit">
            Privacy Statement
          </Link>
        </Typography>
      </Container>
    </Box>
  );
}