import React from "react"
import PageContentWrapper from "../components/common/PageContentWrapper"
import { PrivacyPolicyContent } from "../components/features/dataCollection/PrivacyPolicyContent"

function PrivacyPolicyPage(){
    return(
        <PageContentWrapper showBreadcrumbs={false} pageTitle='CFUTDB | Privacy Policy'>
            <PrivacyPolicyContent/>
        </PageContentWrapper>
    )
}

export default PrivacyPolicyPage