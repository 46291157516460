import { createTheme } from '@mui/material/styles';

// Create a theme instance.
let theme = createTheme()

theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#dc004e',
    },
    common: {
      white: '#ffffff',
    },
    action: {
      active: '#ffffff',
    },
    info: {
      main: 'rgba(255, 255, 255, 0.5)',
      light: 'rgba(255, 255, 255, 0.2)',
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8, // Default spacing value
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1926,
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  typography: {
    fontFamily: [
      '"Roboto"',
      '"Helvetica"',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: 30,
      fontWeight: 500,
      lineHeight: 1.2,
      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
      },
    },
    h2: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.3,
      [theme.breakpoints.up('sm')]: {
        fontSize: 25,
      },
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.3,
      [theme.breakpoints.up('sm')]: {
        fontSize: 23,
      },
    },
    h6: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 1.3,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    body1: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.5,
      [theme.breakpoints.up('sm')]: {
        fontSize: 15,
      },
    },
    body2: {
      fontSize: 8,
      fontWeight: 400,
      lineHeight: 1.5,
      [theme.breakpoints.up('sm')]: {
        fontSize: 11,
      },
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.75,
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.75,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 8,
      fontWeight: 400,
      lineHeight: 1.66,
      [theme.breakpoints.up('sm')]: {
        fontSize: 12,
      },
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 2.66,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#f0f0f0',
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
        },
      },
    },
  }
})

export default theme;
