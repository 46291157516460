import React, { useEffect, useState } from 'react'
import Navbar from './components/features/navbar/Navbar'
import { Box, Container } from '@mui/material'
import { Router } from './Router'
import { initGoogleAnalytics } from './utils/initGoogleAnalytics';
import {ConsentBanner} from './components/features/dataCollection/ConsentBanner';
import { Footer } from './components/features/footer/Footer';
import Cookies from 'js-cookie';
import { getTopLevelDomain } from './utils/getTopLevelDomain';
import ReactGA from 'react-ga4'
import { useQueryClient } from 'react-query';
import { getPlayersData } from './services/getPlayerData';
import { useAtomValue } from 'jotai';
import { filterAtom } from './data/atoms';

function App() {
  const [showConsentBanner, setShowConsentBanner] = useState(false)
  const queryClient = useQueryClient()
  const playerFilters = useAtomValue(filterAtom)

  const handleAcceptCookies = () => {
    Cookies.set('consentCookie', 'accepted', { expires: 365 })
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGoogleAnalytics(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.event({
        category: 'User',
        action: 'Consented to cookies',
        label: 'Cookies Consent'
      })
    }
  };

  const handleDeclineCookies = () => {
    const topLevelDomain = getTopLevelDomain()
    Cookies.set('consentCookie', 'declined', { expires: 1 })
    Cookies.remove("_ga",{ path: '/', domain: topLevelDomain})
    const gaCookieId = process.env.REACT_APP_GOOGLE_ANALYTICS_COOKIE_ID ?? ''
    Cookies.remove(gaCookieId,{ path: '/', domain: topLevelDomain})
  };

  useEffect(() => {
    const consentCookie = Cookies.get('consentCookie')
    if(!consentCookie){
      setShowConsentBanner(true)
    }
    else if(consentCookie === 'accepted' && process.env.REACT_APP_GOOGLE_ANALYTICS_ID){
      initGoogleAnalytics(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
    }
  }, []);

  useEffect(() => {
    queryClient.prefetchInfiniteQuery(['players', playerFilters], ({ pageParam = null }) => 
      getPlayersData({ pageParam, filters: playerFilters })
    );
  }, [queryClient]);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh', 
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/home-background.jpg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
    >
      <Navbar />
      <Box component="main" sx={{ flex: 1, mt: 2, mb: 4 }}>
        <Container>
          <Router />
        </Container>
      </Box>
      {showConsentBanner && (
        <ConsentBanner  handleAcceptCookies={handleAcceptCookies} handleDeclineCookies={handleDeclineCookies} />
      )}
      <Footer />
    </Box>
  )
}

export default App;
