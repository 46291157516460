import { Position, PositionShortNames } from "../types/positionTypes";
import { PlayerRatings } from "../types/ratingTypes";

//eventually show ratings based on what is relevant to position
export function getPlayerOverviewRatings(playerRatings: PlayerRatings, position: Position): {ratingHeaders: string[], ratingValues: number[]}{
    let ratingHeaders = []
    let ratingValues: number[] = []
    if(position.positionShortName === PositionShortNames.K || position.positionShortName === PositionShortNames.P){
        ratingHeaders = ['KPW','KAC','SPD','ACC','SAC']
        ratingValues = [
            playerRatings.kickingRatings.kickPowerRating,
            playerRatings.kickingRatings.kickAccuracyRating,
            playerRatings.fakeRatings.speedRating,
            playerRatings.fakeRatings.accelerationRating,
            playerRatings.fakeRatings.shortAccuracyRating,
        ]
    }
    else if(position.positionShortName === PositionShortNames.QB){
        ratingHeaders = ['SPD','THP','SAC', 'MAC', 'DAC']
        ratingValues = [
            playerRatings.generalRatings.speedRating,
            playerRatings.passingRatings.throwPowerRating,
            playerRatings.passingRatings.shortAccuracyRating,
            playerRatings.passingRatings.middleAccuracyRating,
            playerRatings.passingRatings.deepAccuracyRating,
        ]
    }
    else if(position.positionShortName === PositionShortNames.RB){
        ratingHeaders = ['SPD','ACC','BTK', 'JKM', 'CTH']
        ratingValues = [
            playerRatings.generalRatings.speedRating,
            playerRatings.generalRatings.accelerationRating,
            playerRatings.ballCarrierRatings.breakTackleRating,
            playerRatings.ballCarrierRatings.jukeMoveRating,
            playerRatings.receivingRatings.catchRating,
        ]
    }
    else if(position.positionShortName === PositionShortNames.WR){
        ratingHeaders = ['SPD','CTH','CIT', 'SPC', 'DRR']
        ratingValues = [
            playerRatings.generalRatings.speedRating,
            playerRatings.receivingRatings.catchRating,
            playerRatings.receivingRatings.catchInTrafficRating,
            playerRatings.receivingRatings.spectacularCatchRating,
            playerRatings.receivingRatings.deepRouteRunningRating,

        ]
    }
    else{
        ratingHeaders = ['SPD','ACC','STR','AGI','COD']
        ratingValues = [
            playerRatings.generalRatings.speedRating,
            playerRatings.generalRatings.accelerationRating,
            playerRatings.generalRatings.strengthRating,
            playerRatings.generalRatings.agilityRating,
            playerRatings.generalRatings.changeOfDirectionRating
        ]
    }

    return {ratingHeaders, ratingValues}
}